import React from "react";
import "./footer.css";

import MailLogo from '../Images/DB_Mail_LogoV2.png';

function Footer() {
	return (
		<footer>
			<div > 
			<a href="mailto:info@donkeyblx.io"> 
				<img src={MailLogo} className="Mail-logo"/>
			</a>
				<div>
					<h6 style={{ fontSize: 12, paddingTop: 4, letterSpacing: 2 }}>COPYRIGHT © DONKEYBLX 2023</h6>
				</div>
			</div>
		</footer>
	);
}

export default Footer;