import './App.css';
import React, { useEffect, useState } from "react";
import Footer from './Components/footer';
import logo from './Images/DB_logo_paper.png';
import Game1Logo from './Images/SBS_512.png';
import Game2Logo from './Images/WordyBirdyLogo.png';
import Hamburger from 'hamburger-react'
import DeviceOrientation from "./Components/DeviceOrientation";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";



  
function App() {

  const [isOpen, setOpen] = useState(false)

  return (
    <Router>
      <Route path="/stormbirds-solitaire-help">
      <div className = "AppPrivacy">
      <div class="section-div">
          <p>
            <span className='left-side-privacy'>
            <br/>
            <br/>
            RULES - HOW TO PLAY 
            <br/>
            <br/>
            Klondike Stormbirds Solitaire is played with a standard 52-card deck, without Jokers.
            <br/>
            <br/>
            After shuffling, a tableau of seven fanned piles of cards is laid from left to right. From left to right, each pile contains one more card than the last. The first and left-most pile contains a single upturned card, the second pile contains two cards, the third pile contains three cards, the fourth pile contains four cards, the fifth pile contains five cards, the sixth pile contains six cards, and the seventh pile contains seven cards. The topmost card of each pile is turned face up.
            <br/>
            <br/>
            The remaining cards form the stock and are placed facedown at the upper left of the layout.
            <br/>
            <br/>
            The four foundations (light rectangles in the upper right of the figure) are built up by suit from Ace (low in this game) to King, and the tableau piles can be built down by alternate colors. Every face-up card in a partial pile, or a complete pile, can be moved, as a unit, to another tableau pile on the basis of its highest card. Any empty piles can be filled with a King, or a pile of cards with a King. The aim of the game is to build up four stacks of cards starting with Ace and ending with King, all of the same suit, on one of the four foundations, at which time the player would have won. There are different ways of dealing the remainder of the deck from the stock to the waste, including the following:
            <br/>
            <br/>
            Turning three cards at once to the waste, with no limit on passes through the deck.
            <br/>
            Turning three cards at once to the waste, with three passes through the deck.
            <br/>   
            Turning one card at a time to the waste, with three passes through the deck.
            <br/>
            Turning one card at a time to the waste with only a single pass through the deck, and playing it if possible.
            <br/>
            Turning one card at a time to the waste, with no limit on passes through the deck.
            <br/>
            If the player can no longer make any meaningful moves, the game is considered lost. At this point, winning is impossible.
            <br/>
            <br/>
            <br/>
            </span>
            </p>
            </div>
        </div>
      </Route>
    <Route path="/privacy">
      <div className = "AppPrivacy">
      <div class="section-div">
          <p>
            <span className='left-side-privacy'>
            <br/>
            <br/>
            WE VALUE YOUR PRIVACY 
            <br/>
            <br/>
            We value the privacy of individuals who visit our website at https://donkeyblx.io (the “Website”), and any of our other applications, or services that link to this Privacy Policy (collectively, the "Services"). This Privacy Policy ("Policy") is designed to explain how we collect, use, and share information from users of the Services. This Policy is incorporated by reference into our Terms of Use. By agreeing to this Policy through your continued use of the Services, you agree to the terms and conditions of this Policy. 
            <br/>
            <br/>
            WHAT TYPE OF INFORMATION WE COLLECT? 
            <br/>
            <br/>
            We collect any information you provide to us when you use the Services. You may provide us with information in various ways on the Services. Personal Information: When you use our Services, you are required to provide us with your name and email address. 
            Financial Information: We may collect necessary financial information such as your wallet address when you use our Services. Usage information: We may collect information about how you access and use our Services, your actions on the Services, including your interactions with others on the Services, comments and posts you make in our public discussion forums, and other content you may provide. Technical Data: We may collect data such as IP (internet protocol) address, ISP (internet Services provider), the web browser used to access the Services, the time the Services was accessed, which web pages were visited, operating system and platform, a mobile device-type identifier, and other technology on the devices you use to access our Services. We may also access your photo and camera roll or Face ID through our mobile application with your permission. Communications: We may receive additional information about you when you contact us directly. For example, we will receive your email address, the contents of a message or attachments that you may send to us, and other information you choose to provide when you contact us through email. 
            <br/>
            <br/>
            WE COLLECT COOKIES 
            <br/>
            <br/>
            When you use our Services, we may collect information from you through automated means, such as cookies, web beacons, and web server logs. By using the Services, you consent to the placement of cookies, beacons, and similar technologies in your browser and on emails in accordance with this Policy. The information collected in this manner includes IP address, browser characteristics, device IDs and characteristics, operating system version, language preferences, referring URLs, and information about the usage of our Services. We may use this information, for example, to ensure that the Services functions properly, to determine how many users have visited certain pages or opened messages, or to prevent fraud. We also work with analytics providers which use cookies and similar technologies to collect and analyze information about use of the Services and report on activities and trends. If you do not want information collected through the use of cookies, there is a procedure in most browsers that allows you to automatically decline cookies or be given the choice of declining or accepting the transfer to your computer of a particular cookie (or cookies) from a particular site. You may also wish to refer to http://www.allaboutcookies.org/manage-cookies/index.html. If, however, you do not accept cookies, you may experience some inconvenience in your use of the Services. 
            <br/>
            <br/>
            HOW DO WE USE THE INFORMATION WE COLLECT? 
            <br/>
            <br/>
            Operating, maintaining, enhancing and providing features of the Services, providing Services and information that you request, responding to comments and questions, and otherwise providing support to users. 
            Understanding and analyzing the usage trends and preferences of our users, improving the Services, developing new products, services, features, and functionality. 
            Contacting you for administrative or informational purposes, including by providing customer services or sending communications, including changes to our terms, conditions, and policies. 
            For marketing purposes, such as developing and providing promotional and advertising materials that may be useful, relevant, valuable or otherwise of interest. 
            Personalizing your experience on the Services by presenting tailored content. 
            We may aggregate data collected through the Services and may use and disclose it for any purpose. 
            For our business purposes, such as audits, security, compliance with applicable laws and regulations, fraud monitoring and prevention. 
            Complying with legal and regulatory requirements.
            Protecting our interests, enforcing our Terms of Use or other legal rights. 
            <br/>
            <br/>
            WHEN AND WITH WHOM DO WE SHARE THE INFORMATION WE COLLECT? 
            <br/>
            <br/>
            We do not rent, sell or share your information with third parties except as described in this Policy. We may share your information with the following: 
            Entities in our group or our affiliates in order to provide you with the Services. 
            Our third-party services providers who provide services such as website hosting, data analysis, customer services, email delivery, auditing, and other services. 
            Credit bureaus and other third parties who provide Know Your Customer and Anti-Money Laundering services. 
            Potential or actual acquirer, successor, or assignee as part of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in bankruptcy or similar proceedings). 
            If required to do so by law or in the good faith belief that such action is appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, including public and government authorities outside your country of residence; (d) to enforce our terms and conditions; (e) to protect our operations or those of any of our subsidiaries; (f) to protect our rights, privacy, safety or property, and/or that of our subsidiaries, you or others; and (g) to allow us to pursue available remedies or limit the damages that we may sustain. In addition, we may use third party analytics vendors to evaluate and provide us with information about your use of the Services. We do not share your information with these third parties, but these analytics services providers may set and access their own cookies, pixel tags and similar technologies on the services and they may otherwise collect or have access to information about you which they may collect over time and across different websites. For example, we use Google Analytics to collect and process certain analytics data. Google provides some additional privacy options described at https://www.google.com/policies/privacy/partners. We may use and disclose aggregate information that does not identify or otherwise relate to an individual for any purpose, unless we are prohibited from doing so under applicable law. 
            <br/>
            <br/>
            THIRD-PARTY SERVICES 
            <br/>
            <br/>
            We may display third-party content on the Services. Third-party content may use cookies, web beacons, or other mechanisms for obtaining data in connection with your viewing of and/or interacting with the third-party content on the Services. You should be aware that there is always some risk involved in transmitting information over the internet. While we strive to protect your Personal Information, we cannot ensure or warrant the security and privacy of your Personal Information or other content you transmit using the Services, and you do so at your own risk. Please note that we cannot control, nor will we be responsible for the Personal Information collected and processed by third parties, its safekeeping or a breach thereof, or any other act or omission pertaining to it and their compliance with applicable privacy laws or regulations. We advise you to read the respective privacy policy of any such third party and use your best discretion. 
            <br/>
            <br/>
            HOW WE PROTECT YOUR PERSONAL INFORMATION 
            <br/>
            <br/>
            You acknowledge that no data transmission over the internet is totally secure. Accordingly, we cannot warrant the security of any information which you transmit to us. That said, we do use certain physical, organizational, and technical safeguards that are designed to maintain the integrity and security of information that we collect.  You need to help us prevent unauthorized access to your account by protecting and limiting access to your account appropriately (for example, by logging out after you have finished accessing your account). You will be solely responsible for keeping your account against any unauthorized use. While we seek to protect your information to ensure that it is kept confidential, we cannot absolutely guarantee its security. However, we do not store any passwords as an added layer of security. Please be aware that no security measures are perfect or impenetrable and thus we cannot and do not guarantee the security of your data. While we strive to protect your Personal Information, we cannot ensure or warrant the security and privacy of your Personal Information or other content you transmit using the Services, and you do so at your own risk. It is important that you maintain the security and control of your account credentials. 
            <br/>
            <br/>
            HOW LONG DO WE KEEP YOUR INFORMATION? 
            <br/>
            <br/>
            We will retain your Information for as long as necessary to provide our Services, and as necessary to comply with our legal obligations (including those specific to financial transactions), resolve disputes, and enforce our policies. Retention periods will be determined taking into account the type of information that is collected and the purpose for which it is collected, bearing in mind the requirements applicable to the situation and the need to destroy outdated, unused information at the earliest reasonable time. 
            <br/>
            <br/>
            YOUR RIGHTS 
            <br/>
            <br/>
            You may, of course, decline to share certain information with us, in which case we may not be able to provide to you some of the features and functionality of the Services. From time to time, we send marketing e-mail messages to our users, including promotional material concerning our Services. If you no longer want to receive such emails from us on a going forward basis, you may opt-out via the "unsubscribe" link provided in each such email. 

            <br/>
            <br/>
            OUR POLICIES CONCERNING CHILDREN
            <br/>
            <br/>
            Most of our websites and games are not for children under the age of 13 and we do not knowingly collect any personal information from children under 13. Children under 13 should not use these websites or games at any time. If we learn that we have inadvertently gathered personal information from a child under 13, we will take reasonable measures to promptly remove that information from our records. Donkeyblx may also provide games which are intended for users of all ages.
            <br/>
            <br/>
            CROSS-BORDER DATA TRANSFER 
            <br/>
            <br/>
            Please note that we may be transferring your information outside of your region for storage and processing and around the globe. By using the Services you consent to the transfer of information to countries outside of your country of residence, which may have data protection rules that are different from those of your country. 
            <br/>
            <br/>
            UPDATES TO THIS PRIVACY POLICY 
            <br/>
            <br/>
            We may make changes to this Policy. The "Last Updated" date at the bottom of this page indicates when this Policy was last revised. If we make material changes, we may notify you through the Services or by sending you an email or other communication. The most current version will always be posted on our website. We encourage you to read this Policy periodically to stay up-to-date about our privacy practices. By continuing to access or use our Services after any revisions become effective, you agree to be bound by the updated Policy. 
            <br/>
            <br/>
            CONTACT US 
            <br/>
            <br/>
            If you have any questions about this Policy, please contact us at admin@donkeyblx.io
            <br/>
            <br/> 
            <br/>
            <br/> 
            APPENDIX - THIRD PARTY SERVICE PROVIDERS
            <br/>
            <br/>
            https://unity.com/legal/privacy-policy
            <br/>
            <br/>     
            https://privacy.microsoft.com/en-us/privacystatement
            <br/>
            <br/>
            <br/>
            <br/>
            Last Updated: 31-Jan 2023
            <br/>
            <br/>
          </span>
          </p>
        </div>
    </div>              
    </Route>
    <Route path="/" exact>
    <div className="Wrapper">
      <div className = "App">
        <div>
          {isOpen &&
          <div className='section-div'>
            <div class="container">
              <div className="Game-header left-side">
                <div>
                <br/>
                <div className='header-text left-side'>GAMES ON THE FACTORY FLOOR_</div>
                <br/>
                <br/>
                <br/>
                <div>
                  <img src={Game1Logo} className="Game-logo1"/>
                </div>
                </div>
              </div>
             <p class="left-side">
                <span className='paragraph-text'>
                  <br />
                  StormBirds Solitaire - a purple pixilated adventure
                  <br />
                  <br />
                  Realised through the gameplay of Classic Solitaire, it is your duty, as N.E.S.T Commander-in-Chief,
                  to rally a newly created squadron of only the most courageous and valiant STORMBIRDS.
                  <br />
                  <br />
                  With the anomaly having only recently re-awoken, time is of the essence. Regiment the flock;
                  bring every suit and file together.
                  <br />
                  <br />
                  And above all -
                  Guard the N.E.S.T.
                  <br />
                  <br />
                </span>
             </p>
             <div className="Game-header left-side">
                <div>
                <br />
                <div>
                  <img src={Game2Logo} className="Game-logo2"/>
                </div>
                </div>
              </div>
             <p className='left-side'>
                <span className='paragraph-text'>
                  <br />
                  Word Chippa
                  <br />
                  <br />
                  A fateful collision brings about a miraculous transition. And now a speechless, levitating man must work together with an outspoken and flightless bird, that they might rewind this karmic judgement.
                  <br />
                  <br />
                  Barking orders whilst pecking a clipboard, donning a construction hat and a tailored hi-vis-jacket, the masterplan that the ‘grounded’ bird had in mind must now be partly undertaken by you.
                  <br />
                  <br />
                  Collect word-twigs. Build word-nests. Feed chicks. And ultimately have fun!
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </span>
             </p>
             </div>
          </div>}
          {!isOpen &&
          <div>
            <br />
            <img src={logo} className="App-logo" alt="logo" />
            <div class="section-div">
            <p>
                <span className='left-side paragraph-text'>
                <br />
                <br />
                  BEYOND THE DISTANT HORIZON A PIXEL OF VIOLET FAINTLY GLOWS;
                  <br />
                  <br />
                  PURPLE 409. A FACTORY MOON ALMOST LOST INTO THE NETHER OF SPACE.
                  <br />
                  <br />
                  <br />
                  <br />
                  ONCE THE PRIDE OF THE NEO-INDUSTRIAL COMPLEX, IT NOW LIES IN RELATIVE OBSURITY. VAST CHASMS MINED FOR PRECIOUS ORES NOW LIE BARREN AND FORGOTTEN. SKELETAL TOWERS SPLINTER THE SKY WITH THEIR LEVIATHAN FORM.
                  <br />
                  <br />
                  <br />
                  AND YET ONE CORPORATION, ALTHOUGH NOW DEFUNCT, NEVER KNEW THE FULL FORCE OF THIS EXODUS. A QUIET COLLECTIVE ABANDONED AN AFFLICTED EARTH - AND IN THEIR HOPES FOR A BETTER SOCIETY, THEY TOOK ROOT IN THE FORMER INFRASTRUCTURE OF DONKEYBLX AND MADE IT THEIR OWN. A BASTION OF FREEDOM.
                  <br />
                  <br />
                  <br />
                  TOILING IN SILENCE; THE COLLECTIVE IS NOW READY TO SPEAK. THE PROPHECY MUST BE FULFILLED; A REVIVAL OF A SIMPLIER TIME.
                  <br />
                  <br />
                  A TIME BEFORE PROFIT AND GREED POISONED THE EARTH AND SCATTERED ITS POPULOUS.
                  <br />
                  <br />
                  <br />
                  THIS COLLECTIVE WILL RETURN TO EARTH, WITH THE DIGITAL TREASURES THEY HAVE MINED. AND THEY WILL BUILD ANEW IN THE WASTELANDS.
                  <br />
                  <br />
                  <br />
                  <br />
                  AND THE TIME IS NOW.
                  <br />
                  <br />
                  </span>
                  <br />
                  <br />
                </p>
                <div className='left-side'>
                <br></br>
                  <br></br>
                </div>
                <div className='left-side paragraph-text'>OUR ADVISORS & PARTNERS</div>
                <div className='left-side'>
                  <br></br>
                  <p className='Paragraph'>{"\u2022"} NEAR Ecosystem</p>
                  <p className='Paragraph'>{"\u2022"} OpenZeppelin</p>
                  <p className='Paragraph'>{"\u2022"} Merit Circle</p>
                  <p className='Paragraph'>{"\u2022"} tokenova</p>
                  <p className='Paragraph'>{"\u2022"} TrustPad</p>
                  <p className='Paragraph'>{"\u2022"} Omniscia</p>   
                  <p className='Paragraph'>{"\u2022"} ukie</p>  
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
                <Footer />
              </div>
          </div>
           }
        </div>
      </div>
    </div>
    </Route>
  </Router>

  );
}

export default App;
